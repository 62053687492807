<template>
	<v-card flat elevation="0" class="min-height-screen h-full">
		<div class="d-flex w-full h-full justify-center align-center">
			<loader />
		</div>
	</v-card>
</template>

<script>
import Loader from '@/components/Loader.vue'

export default {
	name: 'RemoveCart',
	components: {
		Loader,
	},
	beforeCreate() {
		if (!this.$route.query.removed) {
			this.$store.commit('CLEAR_CART_PRODUCTS')
			window.location.href = '/me/cart/remove?removed=true'
		}
	},
}
</script>
